import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import {CurrencyPipe} from "@angular/common";

@Pipe({
  name: 'formatValueProperty'
})
export class FormatValuePropertyPipe implements PipeTransform {
  config = require("../../configs/book-words.json");

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(propertyValue: any): any {
    if (propertyValue === null) {
      return '';
    }

    if (moment(propertyValue, moment.ISO_8601, true).isValid() && propertyValue.toString().length > 4) {
      return moment(propertyValue).format('YYYY-MM-DD HH:mm:ss');
    }

    if (typeof propertyValue === 'number') {
      return this.currencyPipe.transform(propertyValue, 'MXN');
    }

    if (typeof propertyValue === 'boolean') {
      return propertyValue ? 'Si' : 'No';
    }

    const wordInBook: string | undefined = Object.keys(this.config).find(key => key === propertyValue.toString());
    if (wordInBook) {
      return this.config[wordInBook].toString()
    }

    return propertyValue.toString();
  }

}
