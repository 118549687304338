import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {from, lastValueFrom, Observable} from 'rxjs';
import {AppUtilsService} from "../services/app-utils.service";
import * as moment from 'moment-timezone';
import {CognitoService} from "../services/cognito/cognito.service";
import {Router} from "@angular/router";
import {SessionService} from "../services/session.service";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService, private cognitoService: CognitoService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    if (!request.url.includes('/public') && !request.url.includes('/assets/themes/')) {
      let jwt = localStorage.getItem('Token');

      if (!jwt) {
        this.cognitoService.signOut().then(() => {
          this.router.navigate(['/login']);
        });
        return await lastValueFrom(next.handle(request));
      }

      const token = this.sessionService.decodeJWT(jwt);
      if (token) {
        const expirationDate = moment.unix(token.exp);
        const startExpirationLimit = moment.unix(token.exp).subtract(5, "minutes");
        const now = moment();
        if (now.isAfter(startExpirationLimit) && now.isBefore(expirationDate)) {
          await this.cognitoService.refreshSession();
          jwt = await this.cognitoService.getJwtToken();
        } else if (now.isAfter(expirationDate)) {
          this.cognitoService.signOut().then(() => {
            this.router.navigate(['/login']);
          });
        }
      }


      if (jwt) {
        localStorage.setItem('Token', jwt);
      }
      request.headers.set('Authorization', `Bearer ${jwt}`);
    }

    return await lastValueFrom(next.handle(request));
  }
}
