import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {navItems} from "../sidebar-data";
import {configurationDashboard} from "../../configs/configuration-dashboard";
import {SessionService} from "../services/session.service";

@Injectable({
  providedIn: 'root'
})
export class RolGuardService implements CanActivate {
  navItems = navItems;

  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const sessionRol = this.sessionService.getRol();
    const navItem = this.navItems.find((item) => {
      if (item.route?.replace('/', '') === route.routeConfig?.path) {
        return item.rolAvailable?.find((rol) => rol === 'all' || rol === sessionRol);
      }

      return null;
    });
    if (!navItem) {
      this.router.navigate([configurationDashboard.mainRoute]);
    }

    return true;
  }
}
