import {Configs} from "./configs";
import {Validators} from "@angular/forms";
import {globalValidators} from "./global-validators";

export const configurationDashboard: Configs = {
    mainRoute: '/payments',
    components: [
        {
            name: 'payments',
            data: {
                title: 'Pagos Realizados',
                description: 'con tarjeta',
                columns: [
                    {
                        name: 'status',
                        label: 'Status',
                        pipe: 'status',
                        sortable: false,
                        size: 'small',
                        position: 'center'
                    },
                    {
                        name: 'paidDate',
                        label: 'Fecha de pago',
                        pipe: 'date',
                    },
                    {
                        name: 'description',
                        label: 'Descripción',
                    },
                    {
                        name: 'methodNumber',
                        label: 'Numero de tarjeta',
                        size: 'large'
                    },
                    {
                        name: 'amount',
                        label: 'Monto',
                        pipe: 'currency',
                    },
                    {
                        name: 'detail',
                        label: '',
                        type: 'expand',
                        size: 'small',
                        position: 'center'
                    }
                ],
                filters: [
                    {
                        controlName: 'date',
                        type: 'date-range',
                        label: 'Fecha de búsqueda'
                    },
                    {
                        controlName: 'methodNumber',
                        type: 'number',
                        label: 'Ultimos 4 digitos de tarjeta',
                        placeholder: 'xxxx'
                    },
                    {
                        controlName: 'email',
                        type: 'text',
                        label: 'Correo electrónico',
                        placeholder: 'correoelectronico@example.com'
                    },
                    {
                        controlName: 'status',
                        type: 'select',
                        label: 'Estado',
                        placeholder: 'Selecciona',
                        value: [
                            {value: "completed", name: "Completado"},
                            {value: "failed", name: "Fallido"}
                        ]
                    }
                    // {
                    //     controlName: 'amountFrom',
                    //     type: 'number',
                    //     label: 'Monto desde',
                    //     placeholder: '$0.00',
                    //     validators: [Validators.min(0)]
                    // },
                    // {
                    //     controlName: 'amountTo',
                    //     type: 'number',
                    //     label: 'Monto hasta',
                    //     placeholder: '$0.00',
                    //     validators: [Validators.min(0)]
                    // }
                ]
            }
        },
        {
            name: 'payouts',
            data: {
                title: 'Referencias pagadas',
                description: 'hacia tu cuenta bancaria',
                columns: [
                    {
                        name: 'id',
                        hiding: true,
                        label: 'ID',
                    },
                    {
                        name: 'amount',
                        label: 'Monto',
                        pipe: 'currency',
                    },
                    {
                        name: 'account',
                        label: 'Cuenta',
                    },
                    {
                        name: 'concept',
                        label: 'Concepto',
                    },
                    {
                        name: 'operationDate',
                        label: 'Fecha de Operación',
                        pipe: 'date',
                    },
                    {
                        name: 'action',
                        label: '',
                        sortable: false,
                        type: 'redirect',
                        size: 'small',
                        position: 'center'
                    }
                ],
                filters: [
                    {
                        controlName: 'date',
                        type: 'date-range',
                        label: 'Fecha de búsqueda'
                    }
                ]
            }
        },
        {
            name: 'users',
            data: {
                title: 'Usuarios',
                description: 'de la aplicación',
                columns: [
                    {
                        name: 'username',
                        label: 'Usuario',
                        sortable: true,
                        size: 'large'
                    },
                    {
                        name: 'name',
                        label: 'Nombre',
                        sortable: true,
                        size: 'large',
                    },
                    {
                        name: 'rol',
                        label: 'Rol',
                    },
                    {
                        name: 'status',
                        label: 'Status',
                        type: 'status'
                    },
                    {
                        name: 'deleteUser',
                        label: '',
                        size: 'small',
                        type: 'remove'
                    }
                ],
            }
        },
        {
            name: 'applications',
            data: {
                title: 'Aplicaciones',
                description: 'dadas de alta en la plataforma',
                columns: [
                    {
                        name: 'id',
                        label: 'ID',
                        sortable: true,
                        size: 'small',
                        position: 'center'
                    },
                    {
                        name: 'name',
                        label: 'Nombre',
                        size: 'large'
                    },
                    {
                        name: 'bankClabe',
                        label: 'CLABE interbancaria',
                    },
                    {
                        name: 'plan',
                        label: 'Plan',
                        size: 'small'
                    },
                    {
                        name: 'createdAt',
                        label: 'Fecha de creación',
                        pipe: 'date',
                        position: "center"
                    }
                ],
                filters: [
                    {
                        controlName: 'name',
                        type: 'text',
                        label: 'Nombre',
                        placeholder: 'Nombre de Aplicación'
                    },
                    {
                        controlName: 'bankClabe',
                        type: 'text',
                        label: 'CLABE interbancaria',
                        placeholder: 'XXXXXXXXXXXXXXXXXX',
                        validators: [Validators.pattern(globalValidators.ONLY_NUMBERS_VALIDATOR)]
                    }
                ]
            }
        }
    ]
}
