import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {globalValidators} from "../../../configs/global-validators";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {BusinessUnitsService} from "../../pages/business-units/business-units.service";
import {GrowlService} from "../../services/growl.service";
import {ErrorService} from "../../services/error.service";

@Component({
    selector: 'app-new-business-unit',
    templateUrl: './new-business-unit-modal.component.html',
    styleUrls: ['./new-business-unit-modal.component.scss']
})
export class AppNewBusinessUnitModalComponent {
    loading: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<AppNewBusinessUnitModalComponent>,
                private formBuilder: FormBuilder,
                private businessUnitService: BusinessUnitsService,
                private growlService: GrowlService,
                private errorService: ErrorService,) {
    }

    businessUnitForm = this.formBuilder.nonNullable.group({
        key: ['', [Validators.required, this.keyValidator.bind(this)]],
        name: ['', [Validators.required]],
        isPrincipal: [this.data.isEmptyBusinessUnits, [Validators.required]],
        usePrincipalBankClabe: [false, []],
        bankClabe: ['', [Validators.required]],
    });

    keyValidator(control: FormControl) {
        if (control && control.parent) {
            const keyControl = control.parent.get('key');

            if (this.data.businessUnitKey.includes(keyControl?.value)) {
                keyControl?.setErrors({exists: true});
                return {exists: true};
            }
        }
        return null;
    }

    get businessUnirFormControls() {
        return this.businessUnitForm.controls;
    }

    validateBankClabe(control: FormControl) {
        const numericValue = control.value.replace(/[^0-9]/g, '');
        if (control.value !== numericValue) {
            this.businessUnitForm.patchValue({
                bankClabe: numericValue
            });
        }

        if (!globalValidators.BANK_CLABE_VALIDATOR.test(control.value)) {
            control.setErrors({'pattern': true});
            control.markAsTouched();
            control.markAsDirty();
        }
    }

    onChangeUsePrincipalBankClabe($event: MatCheckboxChange) {
        const bankClabeControl = this.businessUnirFormControls['bankClabe'];
        if ($event.checked && this.data.bankClabePrincipal) {
            this.businessUnitForm.patchValue({
                bankClabe: this.data.bankClabePrincipal
            });

            bankClabeControl.disable();
            return;
        }

        bankClabeControl.reset();
        bankClabeControl.enable();
        bankClabeControl.markAsTouched();
        bankClabeControl.markAsDirty();
        return;
    }

    onCancel() {
        this.dialogRef.close();
    }

    onCreateBusinessUnit() {
        this.loading = true;
        const request = this.businessUnitForm.getRawValue();
        this.businessUnitService.register(request).subscribe(() => {
            this.growlService.show('success', 'Aplicación registrada.');
            this.loading = false;
            this.dialogRef.close('SUCCESS');
        }, (error) => {
            console.error('Error calling TCPay API', error);
            this.growlService.show('error', this.errorService.handleError(error));
            this.loading = false;
        });

    }
}
