<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%">
                <div class="row justify-content-center w-100">
                    <div class="col-sm-12">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Nuevo Usuario</h4>

                        <form class="m-t-30" [formGroup]="addUserForm" (ngSubmit)="onAddUser()">
                            <div class="row">
                                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                >Email<sup style="color: red">*</sup>
                                </mat-label
                                >
                                <mat-form-field
                                        [ngClass]="{'mb-4': formControls['email'].touched && formControls['email'].invalid}"
                                        appearance="outline"
                                        class="w-100"
                                        color="primary"
                                >
                                    <input matInput type="text" formControlName="email"/>
                                    <mat-hint
                                            *ngIf="formControls['email'].touched && formControls['email'].invalid"
                                            class="m-b-16 error-msg"
                                    >
                                        <div class="text-error"
                                             *ngIf="formControls['email'].errors && formControls['email'].errors['required']"
                                        >
                                            Email requerido.
                                        </div>
                                        <div class="text-error"
                                             *ngIf="formControls['email'].errors && formControls['email'].errors['email']"
                                        >
                                            El email no cumple el formato adecuado.
                                        </div>
                                    </mat-hint>
                                </mat-form-field>

                                <div class="col-4">
                                    <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                    >Nombre(s)<sup style="color: red">*</sup>
                                    </mat-label
                                    >
                                    <mat-form-field
                                            [ngClass]="{'mb-4': formControls['name'].touched && formControls['name'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                                    >
                                        <input matInput type="text" formControlName="name"/>
                                        <mat-hint
                                                *ngIf="formControls['name'].touched && formControls['name'].invalid"
                                                class="m-b-16 error-msg"
                                        >
                                            <div class="text-error"
                                                 *ngIf="formControls['name'].errors && formControls['name'].errors['required']"
                                            >
                                                Nombre requerido.
                                            </div>
                                        </mat-hint>
                                    </mat-form-field>
                                </div>

                                <div class="col-4">
                                    <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                    >Apellido Paterno
                                    </mat-label
                                    >
                                    <mat-form-field
                                            [ngClass]="{'mb-4': formControls['middleName'].touched && formControls['middleName'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                                    >
                                        <input matInput type="text" formControlName="middleName"/>
                                    </mat-form-field>
                                </div>

                                <div class="col-4">
                                    <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                    >Apellido Materno
                                    </mat-label
                                    >
                                    <mat-form-field
                                            [ngClass]="{'mb-4': formControls['lastName'].touched && formControls['lastName'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                                    >
                                        <input matInput type="text" formControlName="lastName"/>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Rol<sup style="color: red">*</sup>
                            </mat-label
                            >
                            <mat-form-field [ngClass]="{'mb-4': formControls['rol'].touched && formControls['rol'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary">
                                <mat-select formControlName="rol">
                                    <mat-option value="operations">Operaciones</mat-option>
                                    <mat-option value="developer">Desarrollo</mat-option>
                                    <mat-option value="admin">Admin</mat-option>
                                </mat-select>
                                <mat-hint
                                        *ngIf="formControls['rol'].touched && formControls['rol'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['rol'].errors && formControls['rol'].errors['required']"
                                    >
                                        Rol requerido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <mat-dialog-actions align="end">
                                <button mat-button (click)="onCancel()" type="button">Cancelar</button>
                                <button mat-button [disabled]="addUserForm.invalid" type="submit">Guardar</button>
                            </mat-dialog-actions>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
