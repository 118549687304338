import {Component, Input} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
import {AppUtilsService} from "../../../../services/app-utils.service";
import {NgClass} from "@angular/common";
import {configurationDashboard} from "../../../../../configs/configuration-dashboard";

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [RouterModule, NgClass],
  template: `
    <div class="branding d-block text-center pt-2 pb-2 px-3" [ngClass]="{'align-items-center d-flex flex-column pt-2 justify-content-center w-100': collapsed}">
      <a [routerLink]="[configurationDashboard.mainRoute]" style="display: inline-block;">
        <img
            [src]="!collapsed ? logo : logomini" 
            class="align-middle m-2"
            style="max-width: 100%"
            alt="logo"
        />
      </a>
      <div style="border-bottom: 1px solid rgba(182,182,182,0.37); width: 100%"></div>
    </div>
  `,
})
export class BrandingComponent {
  @Input() collapsed: boolean;
  options = this.settings.getOptions();
  logo: string;
  logomini: string;

  constructor(private settings: CoreService, private appUtilsService: AppUtilsService) {
    const setLogos = (logo: string, logomini: string) => {
      this.logo = logo;
      this.logomini = logomini;
    };
    this.appUtilsService.getClientThemeLogos(setLogos);
  }

  protected readonly configurationDashboard = configurationDashboard;
}
