import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {ValidatorUtilsService} from "../../services/validator-utils.service";
import {UsersService} from "../../pages/users/users.service";

@Component({
    selector: 'app-add-user-modal',
    templateUrl: './add-user-modal.component.html',
    styleUrls: ['./add-user-modal.component.scss']
})
export class AppAddUserModalComponent {
    loading: boolean;

    constructor(private formBuilder: FormBuilder,
                private validatorUtils: ValidatorUtilsService,
                private usersService: UsersService,
                public dialogRef: MatDialogRef<AppAddUserModalComponent>) {
    }

    addUserForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
        name: ['', [Validators.required]],
        middleName: ['', []],
        lastName: ['', []],
        rol: ['', [Validators.required]],
    });

    get formControls() {
        return this.addUserForm.controls;
    }

    get formControlsResult() {
        return {
            email: this.formControls.email.value,
            name: this.formControls.name.value,
            middleName: this.formControls.middleName.value,
            lastName: this.formControls.lastName.value,
            rol: this.formControls.rol.value,
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    onAddUser() {
        this.usersService._onAddUserModalClose.next(this.formControlsResult);
    }
}
