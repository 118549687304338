import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNameProperty'
})
export class FormatNamePropertyPipe implements PipeTransform {

  transform(propertyName: string): string {
    const nameWithSpaces = propertyName.replace(/_/g, ' ');

    return nameWithSpaces
        .split(' ')
        .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
        .join(' ');
  }

}
