<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%">
                <div class="row justify-content-center w-100">
                    <div class="col-sm-12">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Regenerar {{data.keyName}}</h4>
                        <p class="f-s-16">Se regenerará tu {{data.keyName}}. Si tienes procesos activos que utilicen el {{data.keyName}} actual puedes seleccionar el tiempo que el {{data.keyName}} actual estará activo.</p>
                        <mat-dialog-actions align="end">
                            <button mat-button (click)="onCancel()">Cancelar</button>
                            <button mat-button [matMenuTriggerFor]="menu">Regenerar</button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item [mat-dialog-close]="0">Ahora</button>
                                <button mat-menu-item [mat-dialog-close]="1">1 hora</button>
                                <button mat-menu-item [mat-dialog-close]="5">5 hora</button>
                                <button mat-menu-item [mat-dialog-close]="10">10 hora</button>
                            </mat-menu>
                        </mat-dialog-actions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
