import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {CognitoService} from "../services/cognito/cognito.service";
import {Observable} from "rxjs";
import {configurationDashboard} from "../../configs/configuration-dashboard";

@Injectable({
  providedIn: 'root'
})
export class SessionGuardService implements CanActivate{

  constructor(private cognitoService: CognitoService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.cognitoService.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated && state.url === '/login') {
        this.router.navigate([configurationDashboard.mainRoute]);
      }

      return true;
    });
  }
}
