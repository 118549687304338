import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";

@Component({
  selector: 'app-regen-api-key-modal',
  templateUrl: './regen-api-key-modal.component.html',
})
export class AppRegenApiKeyModalComponent {

  constructor(public dialogRef: MatDialogRef<AppRegenApiKeyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  onCancel() {
    this.dialogRef.close();
  }
}
