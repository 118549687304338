export const environment = {
    apiUrl: 'https://api.dashboard-dev.tcpip.tech',
    cognito: {
        userPoolId: 'us-east-1_oKKVVsv9m',
        userPoolWebClientId: '3v8qm27k5i3do9mpi826og919b',
    },
    theme: 'banorte'
};



