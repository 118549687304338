import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatusIcon'
})
export class PaymentStatusIconPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case 'AUTHORIZED':
        return 'check_circle';
      case 'AUTHORIZING':
        return 'history';
      case 'completed':
        return 'check_circle';
      default:
        return 'highlight_off';

    }
  }

}
