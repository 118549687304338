import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {MatSnackBarConfig} from "@angular/material/snack-bar";

declare type GrowlType = "success" | "error" | "warn" | "info";

@Injectable({
  providedIn: 'root'
})
export class GrowlService {

  private _successGrowl: Subject<any> = new Subject<any>();
  private _errorGrowl: Subject<any> = new Subject<any>();
  private _warnGrowl: Subject<any> = new Subject<any>();
  private _infoGrowl: Subject<any> = new Subject<any>();

  constructor() { }


  get successGrowl(): Subject<any> {
    return this._successGrowl;
  }

  get errorGrowl(): Subject<any> {
    return this._errorGrowl;
  }

  get warnGrowl(): Subject<any> {
    return this._warnGrowl;
  }

  get infoGrowl(): Subject<any> {
    return this._infoGrowl;
  }

  show(type: GrowlType, message: string, action?: string, config?: MatSnackBarConfig) {
    const data = {message, action, config};

    switch (type) {
      case "success":
        this.successGrowl.next(data);
        break;
      case "error":
        this.errorGrowl.next(data);
        break;
      case "info":
        this.infoGrowl.next(data);
        break;
      case "warn":
        this.warnGrowl.next(data);
        break;
    }
  }
}
