import {Component, OnInit} from '@angular/core';
import {CognitoService} from "../../services/cognito/cognito.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {UpdatePasswordModalService} from "./update-password-modal.service";
import {globalValidators} from "../../../configs/global-validators";
import {ValidatorUtilsService} from "../../services/validator-utils.service";
import {UsersService} from "../../pages/users/users.service";
import {GrowlService} from "../../services/growl.service";
import {ErrorService} from "../../services/error.service";
import {AppUtilsService} from "../../services/app-utils.service";
import {SessionService} from "../../services/session.service";

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password-modal.component.html',
})
export class AppUpdatePasswordModalComponent implements OnInit{
    loading: boolean;
    hidePassword: any = {};
    passwordForm = new FormBuilder().nonNullable.group({
        oldPassword: ['', [Validators.required]],
        newPassword1: ['', [Validators.required, Validators.pattern(globalValidators.PASSWORD_VALIDATOR)]],
        newPassword2: ['', [Validators.required, Validators.pattern(globalValidators.PASSWORD_VALIDATOR), this.validatorUtils.passwordValidator.bind(this)]],
    });

    constructor(private cognitoService: CognitoService,
                private validatorUtils: ValidatorUtilsService,
                private usersService: UsersService,
                private growlService: GrowlService,
                private errorService: ErrorService,
                private sessionService: SessionService,
                private updatePasswordModalService: UpdatePasswordModalService) {
        this.loading = false;
    }

    ngOnInit(): void {
        const controlNames = Object.keys(this.passwordForm.getRawValue());
        for (let controlName of controlNames) {
            this.hidePassword[controlName] = true;
        }
    }

    get formControls() {
        return this.passwordForm.controls;
    }

    async confirmPassword() {
        this.loading = true;
        const user = this.passwordForm.getRawValue();
        const jwt = localStorage.getItem('Token');
        let username = '';
        if (jwt) {
            const payload = this.sessionService.decodeJWT(jwt);
            username = payload.email;
        } else {
            this.growlService.show('error', 'Error inesperado. Usuario no encontrado.');
            this.loading = false;
            return;
        }
        this.usersService.validatePassword(username, user.newPassword1).subscribe(() => {
            this.cognitoService.updateUserPassword(user.oldPassword, user.newPassword1)
                .then((updateStatus) => {
                    this.loading = false;
                    this.growlService.show('success', 'Se actualizó tu contraseña correctamente.');
                    this.updatePasswordModalService.closeModal.next(true);
                }).catch((error) => {
                console.error('There is an error with cognito.', error);
                this.growlService.show('error', this.cognitoService.handleError(error));
                this.loading = false;
            });
        }, (error: any) => {
            console.error('Error calling TCPay API', error);
            this.growlService.show('error', this.errorService.handleError(error));
            this.loading = false;
        });

    }


    validatePassword(control: FormControl) {
        if (!globalValidators.PASSWORD_VALIDATOR.test(control.value)) {
            control.setErrors({ 'pattern': true });
            control.markAsTouched();
            control.markAsDirty();
        }
    }

    togglePasswordVisibility(controlName: string): void {
        if (this.hidePassword.hasOwnProperty(controlName)) {
            this.hidePassword[controlName] = !this.hidePassword[controlName];
        }
    }

    closeModal(): void {
        this.updatePasswordModalService.closeModal.next(true);
    }
}
