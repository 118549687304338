import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRolesFormat'
})
export class UserRolesFormatPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'super_admin':
        return 'Super Admin';
      case 'admin':
        return 'Admin';
      case 'develop':
        return 'Desarrollo';
      case 'operations':
        return 'Operaciones';
      default:
        return '';
    }
  }

}
