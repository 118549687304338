import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {CognitoService} from "../services/cognito/cognito.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private cognitoService: CognitoService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.cognitoService.isAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        this.router.navigate(['/login']);
      }

      return isAuthenticated;
    });
  }
}
