import { Injectable } from '@angular/core';
import {IPage} from "../page.interface";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseTableService {
  abstract _lastFilter: any;
  abstract _applyLastFilter: boolean;
  abstract name: string;

  abstract getAllPageable(pageNumber: number, pageSize: number, filters?: any): Observable<IPage<any>>;
  abstract getAllPageableById(pageNumber: number, pageSize: number, id: string, filters?: any): Observable<IPage<any>>;
  abstract getAll(filters?: any): Observable<any>;
  abstract getDetail(id: string): Observable<any>;
  abstract getReport(filters: any): Observable<any>;

}
