<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class=" d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%; padding: 3rem 2rem 2rem;">
                <div class="row justify-content-center w-100">
                    <div class="col-lg-12 ">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Código de verificación</h4>
                        <span class="f-s-14 d-block mat-body-1 m-t-8" style="color: var(--primary)">Te hemos enviado un código a tu
                            cuenta de correo, ingresa este código para continuar con tu proceso de ingreso a la
                            plataforma.</span>

                        <form class="m-t-30" [formGroup]="codeForm" (ngSubmit)="sendCode()">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">
                                Código de verificación
                            </mat-label>
                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                <input matInput type="number" formControlName="code" />
                                <mat-hint *ngIf="formControls['code'].touched && formControls['code'].invalid"
                                        class="error-msg">
                                    <div class="text-error"
                                            *ngIf="formControls['code'].errors && formControls['code'].errors!['required']">
                                        Código es requerido.
                                    </div>
                                    <div class="text-error"
                                         *ngIf="formControls['code'].errors && (formControls['code'].errors!['min']
                                         || formControls['code'].errors!['max'])">
                                        El valor debe tener 6 digitos.
                                    </div>
                                    <div class="text-error" *ngIf="formControls['code'].errors &&
                                        formControls['code'].errors!['invalidCode']">
                                        El código es invalido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                            <span class="f-s-10 d-block mat-body-1" style="color: gray">No comparta sus
                                credenciales de inicio de sesión ni su código de autenticación multifactor con nadie;
                                Nuestros empleados nunca pedirán el código.</span>


                            <div class="d-flex justify-content-between mt-5 w-100">
                                <div class="d-flex align-items-center" [style]="resendCodeLoading?
                                'pointer-events: none; opacity: 0.6;' : ''">
                                    <a class="text-decoration-none text-primary f-w-500 f-s-14"  style="cursor: pointer;"
                                       (click)="resendCode()">Reenviar nuevo código</a>
                                    <mat-spinner style="height: 20px; width: 35px;" *ngIf="resendCodeLoading"></mat-spinner>
                                </div>
                                <div class="d-flex" style="gap: 1.5rem;">
                                    <button mat-flat-button class="w-100" style="width: auto !important; color: white;"
                                           type="button"  color="warn" (click)="closeModal()">Cancelar</button>
                                    <button mat-flat-button type="submit" class="w-100 signin-button"
                                            style="width: auto !important; color: white" data-spinner-color="var(--primary)"
                                            [ladda]="loading" [disabled]="!codeForm.valid">Entrar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

