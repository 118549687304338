import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgApexchartsModule} from 'ng-apexcharts';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// icons
import {TablerIconsModule} from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

//Import all material modules
import {MaterialModule} from './material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LaddaModule} from "angular2-ladda";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {AppUpdatePasswordModalComponent} from "./modals/update-password/update-password-modal.component";
import {AppForgotPasswordModalComponent} from "./modals/forgot-password-modal/forgot-password-modal.component";
import {AppRecoveryPasswordModalComponent} from "./modals/recovery-password-modal/recovery-password-modal.component";
import {AppRegenApiKeyModalComponent} from "./modals/regen-api-key-modal/regen-api-key-modal.component";
import {AppNewWebhookModalComponent} from "./modals/new-webhook-modal/new-webhook-modal.component";
import {CommonModule} from "@angular/common";
import {SessionInterceptor} from "./interceptors/session.interceptor";
import {AppAddUserModalComponent} from "./modals/add-user-modal/add-user-modal.component";
import {AppRemoveUserModalComponent} from "./modals/remove-user-modal/remove-user-modal.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AppNewApplicationModalComponent} from "./modals/new-application-modal/new-application-modal.component";
import { CaptureCodeComponent } from './modals/capture-code/capture-code.component';
import {AppNewBusinessUnitModalComponent} from "./modals/new-business-unit-modal/new-business-unit.component-modal";


export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
        AppUpdatePasswordModalComponent,
        AppForgotPasswordModalComponent,
        AppRecoveryPasswordModalComponent,
        AppRegenApiKeyModalComponent,
        AppNewWebhookModalComponent,
        AppAddUserModalComponent,
        AppRemoveUserModalComponent,
        AppNewApplicationModalComponent,
        AppNewBusinessUnitModalComponent,
        CaptureCodeComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TablerIconsModule.pick(TablerIcons),
        NgApexchartsModule,
        MatNativeDateModule,
        TablerIconsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        LaddaModule.forRoot({
            style: "slide-left",
            spinnerColor: "var(--primary)",
        }),
        NgbModule,
        MatSnackBarModule
    ],
    exports: [TablerIconsModule],
    bootstrap: [AppComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, // Establece el idioma para las fechas
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    ]
})
export class AppModule {
}
