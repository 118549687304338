<mat-card class="cardWithShadow theme-card">
    <mat-card-header>
        <mat-card-title class="m-b-0">Nueva Unidad de Negocio</mat-card-title>
    </mat-card-header>
    <mat-card-content class="b-t-1">
        <form class="m-t-24" [formGroup]="businessUnitForm" (ngSubmit)="onCreateBusinessUnit()">
            <div>
                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-flex align-items-center"
                >Clave de la unidad<sup style="color: red">*</sup> <mat-icon matTooltip="Clave única para identificar a tu Unidad de Negocio.">help</mat-icon>
                </mat-label
                >
                <mat-form-field
                        [ngClass]="{'mb-4': businessUnirFormControls['key'].touched && businessUnirFormControls['key'].invalid}"
                        appearance="outline"
                        class="w-100"
                        color="primary"
                >
                    <input matInput type="text" formControlName="key"/>
                    <mat-hint
                            *ngIf="businessUnirFormControls['key'].touched && businessUnirFormControls['key'].invalid"
                            class="m-b-16 error-msg"
                    >
                        <div class="text-error"
                             *ngIf="businessUnirFormControls['key'].errors && businessUnirFormControls['key'].errors['required']"
                        >
                            Clave de la unidad requerido.
                        </div>
                        <div class="text-error"
                             *ngIf="businessUnirFormControls['key'].errors && businessUnirFormControls['key'].errors['exists']"
                        >
                            Clave de la unidad existente. Ingresa una clave única nueva.
                        </div>
                    </mat-hint>
                </mat-form-field>

                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-flex align-items-center"
                >Nombre de la Unidad<sup style="color: red">*</sup> <mat-icon matTooltip="Nombre para identificar a tu Unidad de Negocio.">help</mat-icon>
                </mat-label
                >
                <mat-form-field
                        [ngClass]="{'mb-4': businessUnirFormControls['name'].touched && businessUnirFormControls['name'].invalid}"
                        appearance="outline"
                        class="w-100"
                        color="primary"
                >
                    <input matInput type="text" formControlName="name"/>
                    <mat-hint
                            *ngIf="businessUnirFormControls['name'].touched && businessUnirFormControls['name'].invalid"
                            class="m-b-16 error-msg"
                    >
                        <div class="text-error"
                             *ngIf="businessUnirFormControls['name'].errors && businessUnirFormControls['name'].errors['required']"
                        >
                            Nombre de la unidad requerido.
                        </div>
                    </mat-hint>
                </mat-form-field>

                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-flex align-items-center"
                >CLABE Interbancaria<sup style="color: red">*</sup> <mat-icon matTooltip="CLABE a la cual TCPagos depositará las referencias pagadas. Recuerda verificar la CLABE para evitar errores en el proceso de liquidación.">help</mat-icon>
                </mat-label
                >
                <mat-form-field
                        [ngClass]="{'mb-4': businessUnirFormControls['bankClabe'].touched && businessUnirFormControls['bankClabe'].invalid}"
                        appearance="outline"
                        class="w-100"
                        color="primary"
                >
                    <input matInput formControlName="bankClabe" maxlength="18" (input)="validateBankClabe(businessUnirFormControls['bankClabe'])"/>
                    <mat-hint
                            *ngIf="businessUnirFormControls['bankClabe'].touched && businessUnirFormControls['bankClabe'].invalid"
                            class="m-b-16 error-msg"
                    >
                        <div class="text-error"
                             *ngIf="businessUnirFormControls['bankClabe'].errors && businessUnirFormControls['bankClabe'].errors['required']"
                        >
                            CLABE interbancaria requerida.
                        </div>
                        <div class="text-error"
                             *ngIf="businessUnirFormControls['bankClabe'].errors && businessUnirFormControls['bankClabe'].errors['pattern']"
                        >
                            La CLABE Interbancaria debe ser una cadena de 18 dígitos.
                        </div>
                    </mat-hint>
                </mat-form-field>

                <div class="row">
                    <div class="col-6">
                        <mat-checkbox  *ngIf="data.bankClabePrincipal"
                                       class="m-b-12 d-block"
                                       formControlName="usePrincipalBankClabe"
                                       (change)="onChangeUsePrincipalBankClabe($event)">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600">
                                Utilizar CLABE Interbancaria principal
                            </mat-label>
                        </mat-checkbox>
                    </div>
                    <div class="col-6">
                        <mat-checkbox class="m-b-12 d-block" formControlName="isPrincipal">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600">
                                Marcar unidad como <strong>principal</strong><sup style="color: red">*</sup>
                            </mat-label>
                        </mat-checkbox>
                    </div>
                </div>

            </div>

            <mat-dialog-actions align="end">
                <button mat-button (click)="onCancel()" type="button">Cancelar</button>
                <button mat-button [disabled]="businessUnitForm.invalid" type="submit">Crear</button>
            </mat-dialog-actions>
        </form>
    </mat-card-content>
</mat-card>
