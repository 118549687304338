import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {CognitoService} from "./cognito/cognito.service";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiGatewayService {

    constructor(private http: HttpClient) {
    }

    private getHeaderAuth() {
        return new HttpHeaders()
            .set('Authorization', `Bearer ${localStorage.getItem('Token')}`);
    }

    private setHeaders(headers: HttpHeaders | undefined) {
        if (!headers) {
            headers = new HttpHeaders();
        }

        let cognitoHeaders = this.getHeaderAuth();

        for (const key of cognitoHeaders.keys()) {
            headers = headers?.append(key, cognitoHeaders.get(key)!);
        }
        return headers;
    }

    get<T>(uri: string, params?: HttpParams, headers?: HttpHeaders, responseType?: any) {
        headers = this.setHeaders(headers);
        return this.http.get<T>(`${environment.apiUrl}${uri}`, {params, headers, responseType})
    }

    post<T>(uri: string, body: any, params?: HttpParams, headers?: HttpHeaders) {
        headers = this.setHeaders(headers);
        return this.http.post<T>(`${environment.apiUrl}${uri}`, body, {params, headers})
    }

    patch<T>(uri: string, body: any, params?: HttpParams, headers?: HttpHeaders) {
        headers = this.setHeaders(headers);
        return this.http.patch<T>(`${environment.apiUrl}${uri}`, body, {params, headers})
    }

    delete<T>(uri: string, params?: HttpParams, headers?: HttpHeaders) {
        headers = this.setHeaders(headers);
        return this.http.delete<T>(`${environment.apiUrl}${uri}`, {params, headers})
    }
}
