import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FilterPipe} from "./filter.pipe";
import {PaymentStatusIconPipe} from "./payment-status-icon.pipe";
import {FormatNamePropertyPipe} from './format-name-property.pipe';
import {FormatValuePropertyPipe} from './format-value-property.pipe';
import { UserRolesFormatPipe } from './user-roles-format.pipe';


@NgModule({
    declarations: [
        FilterPipe,
        PaymentStatusIconPipe,
        FormatNamePropertyPipe,
        FormatValuePropertyPipe,
        UserRolesFormatPipe
    ],
    providers: [
        CurrencyPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FilterPipe,
        PaymentStatusIconPipe,
        FormatNamePropertyPipe,
        FormatValuePropertyPipe,
        UserRolesFormatPipe
    ]
})
export class PipeModule {
}
