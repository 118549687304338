import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordModalService {
  closeModal: Subject<boolean> = new Subject<boolean>();

  constructor() { }
}
