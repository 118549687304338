import {Component, OnDestroy} from '@angular/core';
import {CaptureCodeService} from "./capture-code.service";
import {FormBuilder, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-capture-code',
  templateUrl: './capture-code.component.html'
})
export class CaptureCodeComponent implements OnDestroy{
  errorObservable!: Subscription;
  loading: boolean;
  resendCodeLoading: boolean;

  constructor(public dialogRef: MatDialogRef<CaptureCodeComponent>, private captureCodeService: CaptureCodeService) {
    this.errorObservable = this.captureCodeService.messageHandle.subscribe({
      next: (error) => {
        if (error === 'invalidCode') {
          this.loading = false;
          this.formControls['code'].setErrors({invalidCode: true});
        } else if (error === 'stopResendCodeLoading') {
          this.resendCodeLoading = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.errorObservable.unsubscribe();
  }

  codeForm = new FormBuilder().nonNullable.group({
    code: [null, [Validators.required, Validators.min(100000), Validators.max(999999)]]
  });

  get formControls() {
    return this.codeForm.controls;
  }


  resendCode(): void {
    this.resendCodeLoading = true;
    this.captureCodeService.resendCode.next(true);
  }

  sendCode(): void {
    this.loading = true;
    this.captureCodeService.enterCode.next(JSON.stringify(this.codeForm.getRawValue().code));
  }

  closeModal(): void {
    this.captureCodeService.closeModal.next(true);
    this.dialogRef.close();
  }

}
