import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CaptureCodeService {
    closeModal: Subject<boolean> = new Subject<boolean>();
    enterCode: Subject<string> = new Subject<string>();
    resendCode: Subject<any> = new Subject<any>();
    messageHandle: Subject<any> = new Subject<any>();

    constructor() { }
}