<mat-card class="cardWithShadow theme-card">
    <mat-card-header>
        <mat-card-title class="m-b-0">Nueva Aplicación</mat-card-title>
    </mat-card-header>
    <mat-card-content class="b-t-1">
        <mat-stepper orientation="vertical" #stepper>
            <mat-step [stepControl]="applicationForm">
                <form class="m-t-24" [formGroup]="applicationForm">
                    <ng-template matStepLabel>Aplicación</ng-template>
                    <div>
                        <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                        >Nombre de Aplicación<sup style="color: red">*</sup>
                        </mat-label
                        >
                        <mat-form-field
                                [ngClass]="{'mb-4': applicationFormControls['name'].touched && applicationFormControls['name'].invalid}"
                                appearance="outline"
                                class="w-100"
                                color="primary"
                        >
                            <input matInput type="text" formControlName="name"/>
                            <mat-hint
                                    *ngIf="applicationFormControls['name'].touched && applicationFormControls['name'].invalid"
                                    class="m-b-16 error-msg"
                            >
                                <div class="text-error"
                                     *ngIf="applicationFormControls['name'].errors && applicationFormControls['name'].errors['required']"
                                >
                                    Nombre de la aplicación requerido.
                                </div>
                            </mat-hint>
                        </mat-form-field>

                        <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                        >CLABE Interbancaria<sup style="color: red">*</sup>
                        </mat-label
                        >
                        <mat-form-field
                                [ngClass]="{'mb-4': applicationFormControls['bankClabe'].touched && applicationFormControls['bankClabe'].invalid}"
                                appearance="outline"
                                class="w-100"
                                color="primary"
                        >
                            <input matInput formControlName="bankClabe" maxlength="18" (input)="validateBankClabe(applicationFormControls['bankClabe'])"/>
                            <mat-hint
                                    *ngIf="applicationFormControls['bankClabe'].touched && applicationFormControls['bankClabe'].invalid"
                                    class="m-b-16 error-msg"
                            >
                                <div class="text-error"
                                     *ngIf="applicationFormControls['bankClabe'].errors && applicationFormControls['bankClabe'].errors['required']"
                                >
                                    CLABE interbancaria requerida.
                                </div>
                                <div class="text-error"
                                     *ngIf="applicationFormControls['bankClabe'].errors && applicationFormControls['bankClabe'].errors['pattern']"
                                >
                                    La CLABE Interbancaria debe ser una cadena de 18 dígitos.
                                </div>
                            </mat-hint>
                        </mat-form-field>

                        <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                        >Categoría<sup style="color: red">*</sup>
                        </mat-label
                        >
                        <mat-form-field [ngClass]="{'mb-4': applicationFormControls['categoryId'].touched && applicationFormControls['categoryId'].invalid}"
                                        appearance="outline"
                                        class="w-100"
                                        color="primary">
                            <mat-select formControlName="categoryId">
                                <mat-option value="1">No Regulados</mat-option>
                                <mat-option value="2">Regulados</mat-option>
                                <mat-option value="3">De Riesgo</mat-option>
                            </mat-select>
                            <mat-hint
                                    *ngIf="applicationFormControls['categoryId'].touched && applicationFormControls['categoryId'].invalid"
                                    class="m-b-16 error-msg"
                            >
                                <div class="text-error"
                                     *ngIf="applicationFormControls['categoryId'].errors && applicationFormControls['categoryId'].errors['required']"
                                >
                                    Categoría requerido.
                                </div>
                            </mat-hint>
                        </mat-form-field>

                        <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block" *ngIf="plans.length > 0"
                        >Plan<sup style="color: red">*</sup>
                        </mat-label
                        >
                        <mat-form-field [ngClass]="{'mb-4': applicationFormControls['plan'].touched && applicationFormControls['plan'].invalid}"
                                        *ngIf="plans.length > 0"
                                        appearance="outline"
                                        class="w-100"
                                        color="primary">
                            <mat-select formControlName="plan">
                                <mat-option *ngFor="let plan of plans" [value]="plan.id">{{plan.name}}</mat-option>
                            </mat-select>
                            <mat-hint
                                    *ngIf="applicationFormControls['plan'].touched && applicationFormControls['plan'].invalid"
                                    class="m-b-16 error-msg"
                            >
                                <div class="text-error"
                                     *ngIf="applicationFormControls['plan'].errors && applicationFormControls['plan'].errors['required']"
                                >
                                    Plan requerido.
                                </div>
                            </mat-hint>
                        </mat-form-field>

                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <button mat-flat-button color="warn" matStepperPrevious (click)="onCancel()">
                            Cancelar
                        </button>
                        <button mat-flat-button color="primary" matStepperNext [disabled]="applicationForm.invalid">Siguiente</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="userForm">
                <form class="m-t-24" [formGroup]="userForm" (ngSubmit)="onCreateApplication()">
                    <ng-template matStepLabel>Cuenta Administrador</ng-template>
                    <div class="row">
                        <div class="col-4">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Nombre(s)<sup style="color: red">*</sup>
                            </mat-label
                            >
                            <mat-form-field
                                    [ngClass]="{'mb-4': userFormControls['userName'].touched && userFormControls['userName'].invalid}"
                                    appearance="outline"
                                    class="w-100"
                                    color="primary"
                            >
                                <input matInput type="text" formControlName="userName"/>
                                <mat-hint
                                        *ngIf="userFormControls['userName'].touched && userFormControls['userName'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="userFormControls['userName'].errors && userFormControls['userName'].errors['required']"
                                    >
                                        Nombre requerido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Apellido Paterno
                            </mat-label
                            >
                            <mat-form-field
                                    [ngClass]="{'mb-4': userFormControls['middleName'].touched && userFormControls['middleName'].invalid}"
                                    appearance="outline"
                                    class="w-100"
                                    color="primary"
                            >
                                <input matInput type="text" formControlName="middleName"/>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Apellido Materno
                            </mat-label
                            >
                            <mat-form-field
                                    [ngClass]="{'mb-4': userFormControls['lastName'].touched && userFormControls['lastName'].invalid}"
                                    appearance="outline"
                                    class="w-100"
                                    color="primary"
                            >
                                <input matInput type="text" formControlName="lastName"/>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                    >Email<sup style="color: red">*</sup>
                    </mat-label
                    >
                    <mat-form-field
                            [ngClass]="{'mb-4': userFormControls['email'].touched && userFormControls['email'].invalid}"
                            appearance="outline"
                            class="w-100"
                            color="primary"
                    >
                        <input matInput type="text" formControlName="email"/>
                        <mat-hint
                                *ngIf="userFormControls['email'].touched && userFormControls['email'].invalid"
                                class="m-b-16 error-msg"
                        >
                            <div class="text-error"
                                 *ngIf="userFormControls['email'].errors && userFormControls['email'].errors['required']"
                            >
                                Email requerido.
                            </div>
                            <div class="text-error"
                                 *ngIf="userFormControls['email'].errors && userFormControls['email'].errors['email']"
                            >
                                El email no cumple el formato adecuado.
                            </div>
                        </mat-hint>
                    </mat-form-field>
                    <div class="d-flex align-items-center justify-content-between">
                        <button mat-flat-button color="warn" matStepperPrevious type="button">
                            Atrás
                        </button>
                        <button mat-flat-button color="primary" type="submit" [ladda]="loading" [disabled]="applicationForm.invalid || userForm.invalid">Crear</button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>
