<mat-toolbar class="topbar" style="padding: 0;">

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    *ngIf="showToggle"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>

  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    *ngIf="!showToggle"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <h6 class="f-s-14 f-w-600 m-0" style="color: white;">{{user?.attributes?.email}}</h6>
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
  >
    <img
      src="/assets/images/profile/blank-profile.webp"
      class="rounded-circle object-cover"
      width="35"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="p-x-20 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0">Perfil de usuario</h6>

      <div class="d-flex align-items-center p-b-24 m-t-16">
        <img
                src="/assets/images/profile/blank-profile.webp"
                class="rounded-circle"
                width="95"
        />
        <div class="m-l-16">
          <h6 class="f-s-14 f-w-600 m-0">{{name}}</h6>
          <h6 class="f-s-10 f-w-600 m-0">{{user?.attributes?.email}}</h6>
          <h6 class="m-t-16 f-s-14 f-w-600 m-0">{{user?.attributes?.profile | userRolesFormat}}</h6>
        </div>
      </div>

      <div class="b-b-1" style="height: 18vh"></div>
    </div>

    <div class="p-y-12 p-x-20">
      <a
              (click)="changePassword()"
              style="color: var(--primary); border-color: var(--primary) !important"
              mat-stroked-button
              color="primary"
              class="w-100"
      >Cambiar Contraseña</a
      >
    </div>

    <div class="p-y-12 p-x-20">
      <a
              (click)="SignOut()"
              [ladda]="loading"
              style="color: var(--primary); border-color: var(--primary) !important"
              mat-stroked-button
              data-spinner-color="var(--primary)"
              color="primary"
              class="w-100"
      >Cerrar Sesión</a
      >
    </div>
  </mat-menu>
</mat-toolbar>
