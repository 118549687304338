import {Component} from '@angular/core';
import {CognitoService} from "../../services/cognito/cognito.service";
import {UpdatePasswordModalService} from "../update-password/update-password-modal.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ForgotPasswordModalService} from "./forgot-password-modal.service";
import {GrowlService} from "../../services/growl.service";

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
})
export class AppForgotPasswordModalComponent {
    loading: boolean;

    constructor(private cognitoService: CognitoService,
                private growlService: GrowlService,
                private forgotPasswordModalService: ForgotPasswordModalService) {
        this.loading = false;
    }

    emailForm = new FormBuilder().nonNullable.group({
        email: [localStorage.getItem('email') || '', [Validators.required, Validators.email]]
    });

    get formControls() {
        return this.emailForm.controls;
    }

    sendCode() {
        this.loading = true;
        this.cognitoService.forgotPassword(this.emailForm.getRawValue().email)
            .then(() => {
                this.growlService.show('success', 'Se envió el código a tu correo.');
                this.loading = false;
                this.forgotPasswordModalService.closeModal.next(true);
            }).catch((error) => {
            console.error('There is an error with cognito.', error);
            this.growlService.show('error', this.cognitoService.handleError(error));
            this.loading = false;
        });
    }
}
