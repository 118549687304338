import {Injectable} from '@angular/core';
import {FormControl} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class ValidatorUtilsService {

    constructor() {
    }

    passwordValidator(control: FormControl) {
        if (control && control.parent) {
            const password1 = control.parent.get('newPassword1');
            const password2 = control.parent.get('newPassword2');

            if (password1 && password2 && password1.value !== password2.value) {
                password2.setErrors({passwordMismatch: true});
                return {passwordMismatch: true};
            } else if (password2) {
                password2.setErrors(null);
                return null;
            }
        }
        return null;
    }
}
