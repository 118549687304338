<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class="d-flex justify-content-start justify-content-lg-center align-items-center h-100 flex-column"
                 style="width: 100%; padding: 2rem">
                <div class="d-flex justify-content-end w-100" style="color: var(--primary)">
                    <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                         viewBox="0 0 24 24"
                         (click)="closeModal()">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18 17.94 6M18 18 6.06 6"/>
                    </svg>
                </div>
                <div class="row justify-content-center w-100">
                    <div class="col-lg-12">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Actualiza tu contraseña</h4>
                        <span class="f-s-14 d-block mat-body-1 m-t-8" style="color: var(--primary)"
                        >Es necesario ingresar una nueva contraseña</span
                        >

                        <form class="m-t-30" [formGroup]="passwordForm" (ngSubmit)="confirmPassword()">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Contraseña Actual</mat-label
                            >
                            <mat-form-field [ngClass]="{'mb-4': formControls['oldPassword'].touched && formControls['oldPassword'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                            >
                                <input matInput formControlName="oldPassword" [type]="hidePassword['oldPassword'] ? 'password' : 'text'" />
                                <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility('oldPassword')">
                                    <mat-icon>{{ hidePassword['oldPassword'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                                </button>
                                <mat-hint
                                        *ngIf="formControls['oldPassword'].touched && formControls['oldPassword'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['oldPassword'].errors && formControls['oldPassword'].errors['required']"
                                    >
                                        Contraseña requerida.
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Nueva Contraseña</mat-label
                            >
                            <mat-form-field [ngClass]="{'mb-4': formControls['newPassword1'].touched && formControls['newPassword1'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                            >
                                <input matInput [type]="hidePassword['newPassword1'] ? 'password' : 'text'" formControlName="newPassword1" (input)="validatePassword(formControls['newPassword1'])" />
                                <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility('newPassword1')">
                                    <mat-icon>{{ hidePassword['newPassword1'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                                </button>
                                <mat-hint
                                        *ngIf="formControls['newPassword1'].touched && formControls['newPassword1'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['newPassword1'].errors && formControls['newPassword1'].errors['required']"
                                    >
                                        Contraseña requerida.
                                    </div>
                                    <div class="text-error"
                                         *ngIf="formControls['newPassword1'].errors && formControls['newPassword1'].errors['pattern']"
                                    >
                                        La contraseña debe contener mínimo 12 caracteres, una mayúscula, un número y un caracter especial.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Confirma Contraseña</mat-label
                            >
                            <mat-form-field [ngClass]="{'mb-5': formControls['newPassword2'].touched && formControls['newPassword2'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                            >
                                <input matInput [type]="hidePassword['newPassword2'] ? 'password' : 'text'" formControlName="newPassword2" (input)="validatePassword(formControls['newPassword2'])" />
                                <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility('newPassword2')">
                                    <mat-icon>{{ hidePassword['newPassword2'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                                </button>
                                <mat-hint
                                        *ngIf="formControls['newPassword2'].touched && formControls['newPassword2'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['newPassword2'].errors && formControls['newPassword2'].errors['required']"
                                    >
                                        Contraseña requerida.
                                    </div>
                                    <div class="text-error"
                                         *ngIf="formControls['newPassword2'].errors && formControls['newPassword2'].errors['pattern']"
                                    >
                                        La contraseña debe contener mínimo 12 caracteres, una mayúscula, un número y un caracter especial.
                                    </div>
                                    <div class="text-error"
                                         *ngIf="formControls['newPassword2'].errors && formControls['newPassword2'].errors['passwordMismatch']"
                                    >
                                        Las contraseñas no coinciden.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <span class="f-s-10 d-block mat-body-1" style="color: gray; text-align: justify; line-height: 15px;">
                                Recomendaciones para el manejo de tu contraseña:
                                Procura cambiar tu contraseña al menos una vez por mes.
                                No compartas tu contraseña.
                                Si notas actividad extraña, cambia tu contraseña de inmediato.
                                Si tu contraseña se ve comprometida cámbiala de inmediato.
                            </span>

                            <button mat-flat-button
                                    data-spinner-color="var(--primary)"
                                    type="submit"
                                    color="primary"
                                    class="w-100 signin-button mt-3"
                                    [ladda]="loading"
                                    [disabled]="!passwordForm.valid">
                                Confirmar
                            </button>
                            <!-- input -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
