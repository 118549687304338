// @ts-ignore
import { NavItem } from './layouts/full/vertical/sidebar/nav-item/nav-item';

export const navItems: NavItem[] = [
    {
    displayName: 'Pagos',
    rolAvailable: ['super_admin', 'admin', 'developer', 'operations'],
    iconName: 'coin',
    route: '/payments',
  },
  {
    displayName: 'Usuarios',
    rolAvailable: ['admin', 'operations'],
    iconName: 'users',
    route: '/users',
  },
  {
    displayName: 'Configuración de cuenta',
    rolAvailable: ['admin', 'developer'],
    iconName: 'settings',
    route: '/account-settings',
  },
  {
    displayName: 'Aplicaciones',
    rolAvailable: ['super_admin'],
    iconName: 'apps',
    route: '/applications',
  },
];
