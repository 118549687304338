import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-remove-user-modal',
    templateUrl: './remove-user-modal.component.html',
})
export class AppRemoveUserModalComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
