import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  handleError(error: any): string {
    if (error && error.hasOwnProperty('error')) {
      if (error.error._embedded && error.error._embedded.errors && error.error._embedded.errors.length > 0) {
        return error.error._embedded.errors.map((e: any) => e.message).join();
      }

      return error.error.message;
    }

    if (error && error.message) {
      return error.message;
    }

    return 'Error inesperado. Si el problema persiste ponte en contacto con nosotros.';
  }
}
