import {Injectable} from '@angular/core';
import {ConfigComponentDataFilter} from "../../configs/configs";
import {FormControl, FormGroup} from "@angular/forms";

import * as moment from 'moment-timezone';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppUtilsService {
    public environmentDefault: boolean = true

    constructor(private http: HttpClient) {
        this.environmentDefault = environment.theme === 'BITBUCKET_THEME' || environment.theme === 'default' || environment.theme === '';
    }

    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    getFilterControls(filters: ConfigComponentDataFilter[]) {
        const formGroup = new FormGroup({});
        let control;
        filters.forEach((filter) => {
            if (filter.type === 'date-range') {
                const paymentDateFrom = new FormControl(moment().subtract(7, 'days').toDate(), filter.validators);
                const paymentDateTo = new FormControl(new Date(), filter.validators);
                formGroup.addControl('paymentDateFrom', paymentDateFrom);
                formGroup.addControl('paymentDateTo', paymentDateTo);
                return;
            }

            control = new FormControl(null, filter.validators);
            formGroup.addControl(filter.controlName, control);
        });
        return formGroup;
    }

    applyClientTheme(): void {
        if (environment.theme === 'BITBUCKET_THEME' || environment.theme == 'default' || environment.theme === '') {return;}
        this.http.get(`/assets/themes/${environment.theme}/theme.json`).subscribe({
            next: async (res: any) => {
                document.documentElement.style.setProperty('--primary', res.primary);
                document.documentElement.style.setProperty('--accent', res.accent);
                document.documentElement.style.setProperty('--bgc-theme', res.background_color);
                document.documentElement.style.setProperty('--bgc-theme-toolbar', res.primary);
                if (res.iconHeader) {
                    document.documentElement.style.setProperty('--bgi-theme-toolbar', `url('${res.iconHeader}')`);
                }
                const favIcon: HTMLLinkElement = document.querySelector('#fav-icon')!;
                const tittle: HTMLLinkElement = document.querySelector('#angular-tittle')!;
                favIcon.href = res.favicon;
                tittle.innerText = res.tittle;
            }
        });
    }

    getClientThemeLogos(setLogos: (logo: string, logomini: string) => void): void {
        if (environment.theme === 'BITBUCKET_THEME' || environment.theme == 'default' || environment.theme === '') {
            setLogos('./assets/images/logos/tcpagos-logo.svg', './assets/images/logos/tcpagos-logo-mini.svg');
            return;
        } else {
            this.http.get(`/assets/themes/${environment.theme}/theme.json`).subscribe({
                next: (res: any) => {
                    setLogos(res.logo, res.logoMini);
                }, error: err => {
                    setLogos('./assets/images/logos/tcpagos-logo.svg', './assets/images/logos/tcpagos-logo-mini.svg');
                }
            });
        }
    }

    getClientThemeName(funcAppName: (name: string | undefined) => void) {
        if (environment.theme === 'BITBUCKET_THEME' || environment.theme == 'default' || environment.theme === '') {
            funcAppName(undefined);
            return;
        }
        this.http.get(`/assets/themes/${environment.theme}/theme.json`).subscribe({
            next: (res: any) => {
                funcAppName(res.name);
            }, error: err => {
                funcAppName(undefined);
            }
        });
    }
}
