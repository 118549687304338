<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%">
                <div class="row justify-content-center w-100">
                    <div class="col-sm-12">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Contraseña recuperada</h4>
                        <p class="f-s-16">Tu contraseña fue actualizada exitosamente.</p>
                        <mat-dialog-actions align="end">
                            <button mat-button mat-dialog-close>Ok</button>
                        </mat-dialog-actions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
