<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <ngb-alert *ngIf="alert" class="login-alert" [type]="alert.type"
                       (closed)="onAlertClosed()">{{ alert.message }}</ngb-alert>
            <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%">
                <div class="row justify-content-center w-100">
                    <div class="col-sm-12">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Nuevo Webhook</h4>

                        <form class="m-t-30" [formGroup]="webhookForm">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >Nombre
                            </mat-label
                            >
                            <mat-form-field
                                    [ngClass]="{'mb-4': formControls['name'].touched && formControls['name'].invalid}"
                                    appearance="outline"
                                    class="w-100"
                                    color="primary"
                            >
                                <input matInput type="text" formControlName="name"/>
                                <mat-hint
                                        *ngIf="formControls['name'].touched && formControls['name'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['name'].errors && formControls['name'].errors['required']"
                                    >
                                        Nombre requerido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                            >URL
                            </mat-label
                            >
                            <mat-form-field
                                    [ngClass]="{'mb-4': formControls['url'].touched && formControls['url'].invalid}"
                                    appearance="outline"
                                    class="w-100"
                                    color="primary"
                            >
                                <input matInput type="text" formControlName="url"/>
                                <mat-hint
                                        *ngIf="formControls['url'].touched && formControls['url'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div class="text-error"
                                         *ngIf="formControls['url'].errors && formControls['url'].errors['required']"
                                    >
                                        URL requerida.
                                    </div>
                                    <div class="text-error"
                                         *ngIf="formControls['url'].errors && formControls['url'].errors['pattern']"
                                    >
                                        Formato inválido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <div class="m-t-30 d-flex align-items-center justify-content-between">
                                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                >Headers
                                </mat-label
                                >
                                <button mat-flat-button
                                        matSuffix
                                        color="primary"
                                        (click)="onAddHeader()">
                                    <mat-icon style="color: white;">add_circle</mat-icon>
                                </button>
                            </div>

                            <div class="m-t-30 header-section" formArrayName="headers">
                                <div *ngFor="let header of formControls['headers'].controls; let i = index">
                                    <form [formGroup]="header">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-sm-5">
                                                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                                >Nombre
                                                </mat-label
                                                >
                                                <mat-form-field
                                                        [ngClass]="{'mb-4': header.get('name').touched && header.get('name').invalid}"
                                                        appearance="outline"
                                                        class="w-100"
                                                        color="primary"
                                                >
                                                    <input matInput type="text" formControlName="name"/>
                                                    <mat-hint
                                                            *ngIf="header.get('name').touched && header.get('name').invalid"
                                                            class="m-b-16 error-msg"
                                                    >
                                                        <div class="text-error"
                                                             *ngIf="header.get('name').errors && header.get('name').errors['required']"
                                                        >
                                                            Nombre de header requerido.
                                                        </div>
                                                    </mat-hint>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-sm-5">
                                                <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
                                                >Valor
                                                </mat-label
                                                >
                                                <mat-form-field
                                                        [ngClass]="{'mb-4': header.get('value').touched && header.get('value').invalid}"
                                                        appearance="outline"
                                                        class="w-100"
                                                        color="primary"
                                                >
                                                    <input matInput type="text" formControlName="value"/>
                                                    <mat-hint
                                                            *ngIf="header.get('value').touched && header.get('value').invalid"
                                                            class="m-b-16 error-msg"
                                                    >
                                                        <div class="text-error"
                                                             *ngIf="header.get('value').errors && header.get('value').errors['required']"
                                                        >
                                                            Valor de header requerido.
                                                        </div>
                                                    </mat-hint>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-sm-2">
                                                <button mat-flat-button
                                                        matSuffix
                                                        color="warn"
                                                        (click)="onRemoveHeader(i)">
                                                    <mat-icon style="color: white;">remove_circle</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <mat-dialog-actions align="end">
                                <button mat-button (click)="onCancel()">Cancelar</button>
                                <button mat-button [disabled]="webhookForm.invalid" [mat-dialog-close]="formControlsResult">Guardar</button>
                            </mat-dialog-actions>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
