import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BlankComponent} from "./layouts/blank/blank.component";
import {FullComponent} from "./layouts/full/full.component";
import {AuthGuardService} from "./guards/auth-guard.service";
import {SessionGuardService} from "./guards/session-guard.service";
import {RolGuardService} from "./guards/rol-guard.service";
import {configurationDashboard} from "../configs/configuration-dashboard";
import {navItems} from "./sidebar-data";

const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        canActivate: [SessionGuardService],
        loadChildren: () =>
          import('./pages/authentication/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: configurationDashboard.mainRoute,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import('./pages/dashboard/dashboards.module').then(
                (m) => m.DashboardsModule
            ),
      },
      {
        path: 'payments',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'payouts',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/payouts/payouts.module').then((m) => m.PayoutsModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'account-settings',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
      },
      {
        path: 'applications',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/applications/applications.module').then((m) => m.ApplicationsModule),
      },
      {
        path: 'business-units',
        canActivate: [AuthGuardService, RolGuardService],
        loadChildren: () =>
            import('./pages/business-units/business-units.module').then((m) => m.BusinessUnitsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {

  constructor() {
    if (configurationDashboard.dashboard) {
      routes[1].children?.unshift({
        path: configurationDashboard.dashboard.path,
        canActivate: [AuthGuardService],
        loadChildren: configurationDashboard.dashboard.loadChildren,
      });

      navItems.unshift({
        displayName: 'Dashboard',
        rolAvailable: ['all'],
        iconName: 'chart-pie',
        route: configurationDashboard.dashboard.route
      });
    }
  }
}
