<div class="blank-layout-container justify-content-center" style="height: auto !important;">
    <div class="position-relative row w-100 h-100">
        <div class="col-12 p-0 d-flex justify-content-center">
            <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100"
                 style="width: 100%">
                <div class="row justify-content-center w-100">
                    <div class="col-lg-9 ">
                        <h4 class="f-w-700 f-s-24 m-0" style="color: var(--accent)">Recupera tu contraseña</h4>
                        <span class="f-s-14 d-block mat-body-1 m-t-8" style="color: var(--primary)"
                        >Se enviará un código de verificación a tu correo para recuperar tu contraseña</span
                        >

                        <form class="m-t-30" [formGroup]="emailForm" (ngSubmit)="sendCode()">
                            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block">
                                Email
                            </mat-label>
                            <mat-form-field [ngClass]="{'mb-2': formControls['email'].touched && formControls['email'].invalid}"
                                            appearance="outline"
                                            class="w-100"
                                            color="primary"
                            >
                                <input matInput formControlName="email" />
                                <mat-hint
                                        *ngIf="formControls['email'].touched && formControls['email'].invalid"
                                        class="m-b-16 error-msg"
                                >
                                    <div
                                            class="text-error"
                                            *ngIf="formControls['email'].errors && formControls['email'].errors['required']"
                                    >
                                        Email es requerido.
                                    </div>
                                    <div
                                            class="text-error"
                                            *ngIf="formControls['email'].errors && formControls['email'].errors['email']"
                                    >
                                        El email debe ser válido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <button mat-flat-button
                                    type="submit"
                                    color="primary"
                                    class="w-100 signin-button mt-5"
                                    style="width: auto !important; left: 80%; color: white"
                                    data-spinner-color="var(--primary)"
                                    [ladda]="loading"
                                    [disabled]="!emailForm.valid">
                                Enviar
                            </button>
                            <!-- input -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
