import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../../environments/environment";
import {Amplify, Auth} from "aws-amplify";
import {ICognitoUser} from "./cognito-user.interface";
import {UsersService} from "../../pages/users/users.service";

@Injectable({
    providedIn: 'root'
})
export class CognitoService {

    private authenticationSubject: BehaviorSubject<any>;
    public userSubject: BehaviorSubject<any>;
    public emailSubject: BehaviorSubject<any>;

    constructor() {
        Amplify.configure({
            Auth: environment.cognito,
            authenticationFlowType: 'CUSTOM_AUTH'
        });

        this.authenticationSubject = new BehaviorSubject<boolean>(false);
        this.userSubject = new BehaviorSubject<any>(null);
        this.emailSubject = new BehaviorSubject<any>(null);
    }

    public async signIn(user: ICognitoUser): Promise<any> {
        let user1 = await Auth.signIn(user.email, user.password);
        this.authenticationSubject.next(true);
        return Promise.resolve(user1);
    }

    public async sendCustomChallengeAnswer(cognitoUser: any, answer: string): Promise<any> {
        let user = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
        this.authenticationSubject.next(true);
        return Promise.resolve(user);
    }

    public async signOut(): Promise<any> {
        return Auth.signOut()
            .then(() => {
                this.authenticationSubject.next(false);
            });
    }

    public signOutSubject(): void {
        this.authenticationSubject.next(false);
    }

    public async isAuthenticated(): Promise<boolean> {
        if (this.authenticationSubject.value) {
            return Promise.resolve(true);
        } else {
            try {
                let user = await this.getUser();
                return !!user;
            } catch (e) {
                return false;
            }
        }
    }

    public getUser(): Promise<any> {
        return Auth.currentUserInfo();
    }

    public async getJwtToken(): Promise<any> {
        let res = await Auth.currentSession();
        let idToken = res.getIdToken();
        return idToken.getJwtToken();
    }

    public changeUserPassword(newPsw: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            Auth.completeNewPassword(this.userSubject.value, newPsw)
                .then((x) => resolve(x))
                .catch((e) => reject(e));
        });
    }

    public handleError(error: { name: string; code: string }): string {
        switch (error.code) {
            case 'NotAuthorizedException':
                return 'Usuario y/o contraseña incorrectos.'
            case 'ExpiredCodeException':
                return 'El código de verificación expiró.'
            case 'CodeMismatchException':
                return 'El código de verificación es invalido. Por favor intenta de nuevo.'
            case 'UsernameExistsException':
                return 'El usuario registrado ya existe.'
            case 'InvalidParameterException':
                return 'Parámetros inválidos. Verifica tu petición.'
            case 'InvalidPasswordException':
                return 'La contraseña proporcionada no cumple con los parámetros requeridos.'
            case 'UserNotFoundException':
                return 'Usuario no encontrado.'
            case 'LimitExceededException':
                return 'Excediste el número de peticiones por minuto, intenta más tarde. Si el problema persiste ponte en contacto con nosotros.'
            default:
                return 'Error inesperado, intenta más tarde. Si el problema persiste ponte en contacto con nosotros.'
        }
    }

    public async updateUserPassword(oldPassword: string, newPassword: string): Promise<"SUCCESS"> {
        let user = await Auth.currentAuthenticatedUser();
        return Auth.changePassword(user, oldPassword, newPassword);
    }

    async forgotPassword(email: string) {
        return await Auth.forgotPassword(email);
    }

    async forgotPasswordSubmit(email: string, code: string, newPassword: string) {
        return await Auth.forgotPasswordSubmit(email, code, newPassword);
    }

    async refreshSession() {
        const user = await Auth.currentUserPoolUser();
        const session: any = await Auth.currentSession();
        const refreshToken = session.refreshToken;
        const refreshRequest = new Promise((res, rej) => {
            user.refreshSession(refreshToken, (data: any) => {
                return res(data);
            });
        });
        await refreshRequest;
    }
}
