import {Component} from '@angular/core';
import {CognitoService} from "../../services/cognito/cognito.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {INewWebhook} from "../../pages/account-settings/new-webhook.interface";

@Component({
    selector: 'app-new-webhook-modal',
    templateUrl: './new-webhook-modal.component.html',
    styleUrls: ['./new-webhook-modal.component.scss']
})
export class AppNewWebhookModalComponent {
    loading: boolean;
    alert: { type: string; message: string } | null;

    constructor(private formBuilder: FormBuilder,
                public dialogRef: MatDialogRef<AppNewWebhookModalComponent>) {
        this.loading = false;
        this.alert = null;
    }

    webhookForm = this.formBuilder.nonNullable.group({
        name: ['', [Validators.required]],
        url: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        headers: this.formBuilder.array([])
    });

    createHeaderFormGroup(): FormGroup {
        return this.formBuilder.nonNullable.group({
            name: ['', Validators.required],
            value: ['', Validators.required]
        });
    }

    get formControls() {
        return this.webhookForm.controls;
    }

    get formControlsResult() {
        const headersResult = [];
        for (let header of this.webhookForm.controls.headers.controls) {
            const headerRawValue = header.getRawValue() as { name: string, value: string };
            headersResult.push(headerRawValue);
        }

        return {
            name: this.formControls.name.value,
            url: this.formControls.url.value,
            headers: headersResult
        }
    }

    onAlertClosed() {
        this.alert = null;
    }

    saveWebhook() {

    }

    onAddHeader() {
        const headersArray = this.formControls.headers as FormArray;
        headersArray.push(this.createHeaderFormGroup());
    }

    onRemoveHeader(controlIndex: number) {
        const headersArray = this.formControls.headers as FormArray;
        headersArray.removeAt(controlIndex);
    }

    onCancel() {
        this.dialogRef.close();
    }
}
